import React from 'react';
import { ERoutes } from 'src/config/enums';
import { EOxStoryVariant, OxStory } from 'src/components/OxStory';
import { graphql, useStaticQuery } from 'gatsby';

export const TheTreatments = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        query {
            ourStoryDesktopImage: file(relativePath: { eq: "homepage/Ourstory-Desktop.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            ourStoryTabletImage: file(relativePath: { eq: "homepage/Ourstory-Tablet.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            ourStoryMobileImage: file(relativePath: { eq: "homepage/Ourstory-Mobile.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);

    const images = [
        {
            ...data.ourStoryMobileImage.childImageSharp.gatsbyImageData,
            media: `(max-width: 768px)`
        },
        {
            ...data.ourStoryTabletImage.childImageSharp.gatsbyImageData,
            media: `(max-width: 1023px)`
        },
        {
            ...data.ourStoryDesktopImage.childImageSharp.gatsbyImageData,
            media: `(min-width: 1024px)`
        }
    ];

    return (
        <OxStory
            fluidImagesArray={images}
            label="THE TREATMENTS"
            title="Next gen facial aesthetics"
            description="Ouronyx approaches facial aesthetic treatments differently. Built on a unique consultation approach that enables the client to aspire to the best version of themselves, it is deeply rooted in advanced technologies that guide, evaluate, and improve the individual’s journey. Honouring individual beauty for an outcome that’s fresh and alive with expression; your best self, brought to life."
            linkTitle="Book a Consultation"
            linkUrl={ERoutes.Appointment}
            variant={EOxStoryVariant.ImageRight}
        />
    );
};
