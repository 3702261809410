import React from 'react';
import { ERoutes } from 'src/config/enums';
import { OxComponentContainer } from 'src/components/OxComponentContainer';
import { graphql, useStaticQuery } from 'gatsby';
import { OxConsultation } from 'src/components/OxConsultation';
import { OxBackToTop } from 'src/components/OxBackToTop';
import { Hero } from 'src/components/Marketing/Hero';
import { TheTreatments } from 'src/components/Marketing/TheTreatments';
import { AdvisoryPanel } from 'src/components/Marketing/AdvisoryPanel';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

const Marketing2Page = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        query {
            consultationTabletImage: file(
                relativePath: { eq: "homepage/BookingBanner-Tablet.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            consultationMobileImage: file(
                relativePath: { eq: "homepage/BookingBanner-Mobile.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);

    const consultationImages = [
        {
            ...data.consultationTabletImage.childImageSharp.gatsbyImageData,
            media: `(max-width: 767px)`
        },
        {
            ...data.consultationTabletImage.childImageSharp.gatsbyImageData,
            media: `(min-width: 768px)`
        }
    ];

    return (
        <>
            <OxPageHelmet
                title="2 | Ouronyx | Luxury Facial Aesthetics"
                description="Ouronyx is the next generation of facial aesthetics. We’re the first global aesthetics destination, helping people to self-optimise with highly personalised, non-surgical treatments, delivered by the world’s best-in-class Doctors."
                keywords="Aesthetic doctor, non surgical, bespoke treatments, luxurious, St James, London, inspiring, leaders"
            />

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <Hero imageAlt={true} />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <TheTreatments />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <OxConsultation
                    label=""
                    title="Book a consultation"
                    linkUrl={ERoutes.Appointment}
                    paragraph={[
                        'Step into your power.',
                        'Book a consultation for a truly transformative experience.'
                    ]}
                    buttonTitle="Book Now"
                    fluidImages={consultationImages}
                />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <AdvisoryPanel primarySliderKey="dr-iman" />
            </OxComponentContainer>

            <OxBackToTop />
        </>
    );
};

export default Marketing2Page;
